import "./App.css";
import { Box, Image, Text } from "@chakra-ui/react";
import "./App.css";
import { Discord, Youtube } from "./Buttons";
import { Canvas, useLoader, useFrame, useThree } from "@react-three/fiber";
import React, { useRef, useState, useEffect } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { motion, useAnimation } from "framer-motion";
// import { useInView } from 'react-intersection-observer';
import { BiDownArrow } from "react-icons/bi";
import { useSpring, animated } from "@react-spring/three";

// CONSTANTS
const MAZE_START = 1000;
const MAZE_SECTION_APPEAR = 1900;
const MAZE_RENDER_FINISH = 2000;
const MAZE_END = 2800;

const PROJECT_START = MAZE_END;
const PROJECT_END = PROJECT_START + 1200;

const FOUNDER_START = PROJECT_END;
const FOUNDER_END = FOUNDER_START + 1200;

const END_OF_PAGE = FOUNDER_END;

// METHODS
const movePosition = (position) => () => {
  // window.scrollTo(0, position);

  // Instead of using scrollTo, you can also use the following to animate the scroll
  window.scrollTo({ top: position, behavior: "smooth" });
  // call an event to the scroll event
  const event = new Event("scroll");
  window.dispatchEvent(event);
};

// function Box3D(props) {
//   // This reference will give us direct access to the mesh
//   const meshRef = useRef();
//   // Set up state for the hovered and active state
//   const [hovered, setHover] = useState(false);
//   const [active, setActive] = useState(false);
//   // Subscribe this component to the render-loop, rotate the mesh every frame
//   useFrame((state, delta) => (meshRef.current.rotation.y += delta));
//   // Return view, these are regular three.js elements expressed in JSX
//   return (
//     <mesh
//       {...props}
//       ref={meshRef}
//       scale={active ? 1 : 2}
//       onClick={(event) => setActive(!active)}
//       onPointerOver={(event) => setHover(true)}
//       onPointerOut={(event) => setHover(false)}
//     >
//       <boxGeometry args={[1, 1, 1]} />
//       <meshStandardMaterial color={hovered ? "hotpink" : "orange"} />
//     </mesh>
//   );
// }

function MazeBox({ position, isOn }) {
  // This reference will give us direct access to the mesh
  const meshRef = useRef();
  const { scale } = useSpring({
    scale: isOn ? [1, 1, 1] : [0, 0, 0], // Animate scale from 0.1 to 1
    config: { mass: 5, tension: 4000, friction: 500, precision: 0.0001 },
  });

  // Set up state for the hovered and active state
  // const [hovered, setHover] = useState(false)
  // const [active, setActive] = useState(false)
  // Subscribe this component to the render-loop, rotate the mesh every frame
  useFrame((state, delta) => {
    // Lerp towards the target scale
    // let target = active ? 1 : 0;
    // meshRef.current.scale = meshRef.current.scale.lerp([target, target, target], 0.1);
  });
  // Return view, these are regular three.js elements expressed in JSX
  return (
    <animated.mesh
      position={position}
      ref={meshRef}
      scale={scale}
      // scale={active ? 1 : 2}
      // onClick={(event) => setActive(!active)}
      // onPointerOver={(event) => setHover(true)}
      // onPointerOut={(event) => setHover(false)}
    >
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color="#4277DD" />
      {/* <meshStandardMaterial color={hovered ? 'hotpink' : 'orange'} /> */}
    </animated.mesh>
  );
}

function getUnvisitedNeighbors(cell, width, grid, visited) {
  const [x, y] = cell;
  const neighbors = [];

  // Check all four possible directions
  [
    [2, 0],
    [-2, 0],
    [0, 2],
    [0, -2],
  ].forEach(([dx, dy]) => {
    const nx = x + dx,
      ny = y + dy;
    if (
      nx >= 0 &&
      nx < width &&
      ny >= 0 &&
      ny < width &&
      !visited.has(`${nx},${ny}`)
    ) {
      neighbors.push([nx, ny]);
    }
  });

  return neighbors;
}

function distanceFromCenter(grid) {
  const width = grid.length;
  const center = Math.floor(width / 2);
  let maze_dict = {};
  let queue = [[center, center, 0]]; // (x, y, distance)
  let max_dist = 0;

  while (queue.length > 0) {
    const [x, y, dist] = queue.shift();

    // Add cell to the dictionary based on its distance
    if (!maze_dict[dist]) {
      maze_dict[dist] = [];
    }
    maze_dict[dist].push([x, y]);

    // Explore neighbors in the four possible directions
    [
      [1, 0],
      [-1, 0],
      [0, 1],
      [0, -1],
    ].forEach(([dx, dy]) => {
      const nx = x + dx;
      const ny = y + dy;
      // Check boundaries and if the next cell is part of the path (grid[nx][ny] === 1)
      if (
        nx >= 0 &&
        nx < width &&
        ny >= 0 &&
        ny < width &&
        grid[nx][ny] === 1
      ) {
        // Check if the cell has not been visited yet by checking if it's not in any distance category
        if (
          !Object.values(maze_dict).some((cells) =>
            cells.some((cell) => cell[0] === nx && cell[1] === ny),
          )
        ) {
          queue.push([nx, ny, dist + 1]);
        }
      }
    });
  }

  max_dist = Math.max(...Object.keys(maze_dict).map((x) => parseInt(x)));

  return { dict: maze_dict, max_dist: max_dist };
}

function GenerateMaze(w) {
  const width = w * 2 + 1; // 10 cells wide and tall
  let grid = new Array(width).fill(0).map(() => new Array(width).fill(0));
  const stack = [];
  const visited = new Set();
  // Start in the middle
  // let current = [Math.floor(width / 2), Math.floor(width / 2)];
  let current = [0, 0];
  stack.push(current);
  visited.add(current.join());
  grid[current[0]][current[1]] = 1;

  while (stack.length > 0) {
    current = stack.pop();
    const neighbors = getUnvisitedNeighbors(current, width, grid, visited);

    if (neighbors.length > 0) {
      stack.push(current); // Put current cell back on stack
      const next = neighbors[Math.floor(Math.random() * neighbors.length)];
      // Remove the wall
      const wall = [(current[0] + next[0]) / 2, (current[1] + next[1]) / 2];
      grid[wall[0]][wall[1]] = 1;
      grid[next[0]][next[1]] = 1;
      visited.add(next.join());
      stack.push(next);
    }
  }

  // print the grid
  // console.log(grid.map((row) => row.join(' ')).join('\n'));

  const mazeDict = distanceFromCenter(grid);
  return mazeDict;
}

const easeInOut = (t, easeAmount) =>
  t < 0.5
    ? 0.5 * Math.pow(2 * t, easeAmount)
    : 1 - 0.5 * Math.pow(2 * (1 - t), easeAmount);
function CameraController({ yOffset, initialY, initialZ }) {
  yOffset = Math.min(1, Math.max(0, easeInOut(yOffset, 3)));
  const { camera } = useThree(); // This hook gives you the default camera directly
  let scale = 17;
  useFrame(() => {
    // const aspectRatio = window.innerWidth / window.innerHeight;
    camera.position.z = initialZ + yOffset * scale;
    camera.position.y = initialY - yOffset * 0.7 * scale;
    camera.rotation.x = (Math.PI / 180) * yOffset * 1.5 * scale;
  });

  return null; // Since it doesn't render anything itself
}

function Maze({ yOffset, height }) {
  // Delay by 12.5%, then end at 67.5%
  let oldHeight = height;
  yOffset /= 0.875;
  yOffset -= height * 0.125;

  // height *= 0.675;
  const w = 12;
  const [mazeDict] = useState(GenerateMaze(w));

  let percent = Math.pow((yOffset * 1.0) / height, 2) * mazeDict["max_dist"];
  if (yOffset < 0) {
    if (yOffset > -oldHeight * 0.125) percent = 0;
    else percent = -1;
    yOffset = 0;
  }

  let t = (yOffset * 1.0) / height;

  if (t > 1) {
    t = 1;
    percent = mazeDict["max_dist"];
  }

  //https://docs.pmnd.rs/react-three-fiber/api/canvas
  return (
    <Canvas
      frameloop={
        yOffset > -50 && yOffset < height + (MAZE_END - MAZE_RENDER_FINISH)
          ? "always"
          : "never"
      } //"demand"}
      gl={{ antialias: true }}
      dpr={Math.min(window.devicePixelRatio, 2) / 2}
      // dpr={.5}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0)",
      }}
      camera={{
        position: [0, 0, w],
        rotation: [(Math.PI / 180) * 0, (Math.PI / 180) * 0, 0],
      }}
    >
      <CameraController yOffset={t} initialZ={w} initialY={0} />
      <ambientLight intensity={Math.PI / 2} />
      <spotLight
        position={[w, w, w]}
        angle={1}
        penumbra={1}
        decay={0.1}
        intensity={Math.PI}
      />
      <pointLight position={[-10, -3, -10]} decay={0} intensity={Math.PI} />
      <pointLight position={[10, -3, 10]} decay={0} intensity={Math.PI / 3} />
      {/* <Box3D position={[-1.2, 0, 1]} /> */}
      {/* <Box3D position={[1.2, 0, 0]} /> */}

      {Object.entries(mazeDict["dict"]).map(
        ([distance, cells], index) =>
          (yOffset >= -100 || distance < 3) &&
          cells.map((cell, cellIndex) => (
            <MazeBox
              key={`${distance}-${cellIndex}`}
              position={[cell[0] - w, cell[1] - w, 0]}
              isOn={distance <= percent}
            />
          )),
      )}
    </Canvas>
  );
}

function ProceduralGenSection({ inView, yOffset, height }) {
  const controls = useAnimation();

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  const above = yOffset < 200;

  const variants = {
    hidden: {
      scale: 1,
      opacity: 0,
      y: above ? 50 : -50, // Add this line to start the element lower
      transition: { duration: 0.5 },
    },
    visible: {
      scale: 1,
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.0,
        duration: 0.5,
      },
    },
  };

  let offset = (yOffset / height) * 200;
  if (yOffset < 0) {
    offset = 0;
  }

  return (
    // https://v2.chakra-ui.com/docs/components/transitions
    <div
      style={{
        position: "fixed",
        top: "16%",
        transform: "translateY(" + -offset + "px)",
        left: "0%",
        width: "100%",
      }}
    >
      <motion.div
        initial="hidden"
        animate={controls}
        variants={variants}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0px 0",
        }}
      >
        <motion.div
          variants={variants}
          style={{
            width: "100%",
            marginTop: "0px",
            fontSize: "40px",
            color: "#73A3E5",
            fontWeight: "regular",
            fontFamily: "Roboto",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            width={{ base: "md", sm: "md", md: "2xl" }}
            maxW={{ base: "sm", sm: "md", md: "2xl" }}
            m="auto"
            px={{ base: 8, sm: 4, md: 16 }}
          >
            <Text
              fontSize={{ base: "24px", sm: "32px", md: "40px" }}
              width="100%"
              mb={-4}
            >
              we build
            </Text>
            <Text
              fontSize={{ base: "32px", sm: "40px", md: "54px" }}
              fontWeight="black"
              width="100%"
              textAlign={"center"}
            >
              procedural generation
            </Text>
            <Text
              fontSize={{ base: "24px", sm: "32px", md: "40px" }}
              textAlign="right"
              width="100%"
              mt={-4}
            >
              games
            </Text>
          </Box>
        </motion.div>
      </motion.div>
    </div>
  );
}

const PROJECTS = [
  {
    title: "Blocky Battlegrounds",
    description: "Casual physics-based party game",
    icon: "bb.jpg",
  },
  {
    title: "Crewbound",
    description: "Infinite open-world survival game",
    icon: "procedural_generation.jpg",
  },
  {
    title: "Trust Me",
    description: "Social engineer the AI to hack the system",
    icon: "unannounced.jpg",
  },
  {
    // title: "Block & Load",
    title: "Rogue Arsenal",
    description: "Roguelike with endlessly customizable weapons",
    icon: "unannounced.jpg",
  },
  // {
  // title: "coming soon :)",
  // description: "procedural generation with ai",
  // }
];
function ProjectSection({ inView, yOffset, height }) {
  const controls = useAnimation();
  // const { ref, inView } = useInView({
  //   triggerOnce: true,
  //   threshold: 0.5  // Adjust the threshold as needed
  // });

  // const y = useTransform(scrollY, [1000, 1400], [0, -400]);

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  // Assuming this chunk is 400
  const above = yOffset < height / 2;
  const offset = -yOffset + height / 2;

  const variants = {
    hidden: {
      scale: 1,
      opacity: above ? 0 : 0,
      y: above ? 100 : -100, // Add this line to start the element lower
      transition: { duration: 0.5 },
    },
    visible: {
      scale: 1,
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.0,
        duration: 0.5,
      },
    },
  };

  return (
    // https://v2.chakra-ui.com/docs/components/transitions
    <div
      style={{ position: "fixed", transform: "translateY(" + offset + "px)" }}
    >
      <motion.div
        initial="hidden"
        animate={controls}
        variants={variants}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "50px 0",
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="top"
          justifyContent="center"
        >
          <Text
            color="white"
            fontWeight="bold"
            fontFamily="Roboto"
            fontSize={{ base: "24px", sm: "32px", md: "40px" }}
            textAlign="center"
            mb={4}
            ml={{ base: 8, sm: 12, md: 12 }}
          >
            GAMES
          </Text>

          <Text
            mt={-0.5}
            color="gray.300"
            fontFamily="Roboto"
            fontSize={{ base: "8px", sm: "12px", md: "16px" }}
            textAlign="center"
          >
            (alpha)
          </Text>
        </Box>
        <Box
          // width={{ base: "254px", sm: "480px", md: "780px" }}
          width="100%"
          maxW={{ base: "sm", sm: "md", md: "3xl" }}
          height="1px"
          backgroundColor="white"
          mb={8}
        ></Box>

        <Box
          width="100%"
          maxW={{ base: "sm", sm: "md", md: "3xl" }}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          m="auto"
          p="auto"
        >
          {PROJECTS.map((project, index) => (
            <Box
              gap={{ base: "20px", sm: "40px", md: "60px" }}
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              px={{ base: 4, sm: 6, md: 8 }}
              mb={{ base: 4, sm: 6, md: 12 }}
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                {/* <img src="luke.jpg" alt="Founder 1" style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> */}
                <Box
                  // background={"#4277DD"}
                  width="100%"
                  mr={{ base: 4, sm: 8, md: 12 }}
                  // height={{ base: "100px", sm: "200px", md: "300px" }}
                >
                  <Text
                    color="white"
                    fontWeight="bold"
                    fontFamily="Roboto"
                    fontSize={{ base: "24px", sm: "28px", md: "40px" }}
                  >
                    {project.title}
                  </Text>

                  <Text
                    color="gray.300"
                    fontFamily="Roboto"
                    fontSize={{ base: "16px", sm: "20px", md: "32px" }}
                  >
                    {project.description}
                  </Text>
                </Box>

                <Image
                  src={project.icon}
                  alt={project.title}
                  width={{ base: "100px", sm: "200px", md: "280px" }}
                  height={{ base: "100px", sm: "200px", md: "280px" }}
                />
              </Box>
            </Box>
          ))}
        </Box>
      </motion.div>
    </div>
  );
}

function FoundersSection({ inView, scrollY, yOffset, height }) {
  const controls = useAnimation();
  // const { ref, inView } = useInView({
  //   triggerOnce: true,
  //   threshold: 0.5  // Adjust the threshold as needed
  // });

  // const y = useTransform(scrollY, [1000, 1400], [0, -400]);

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  // Assuming this chunk is 400
  const above = yOffset < height / 2;
  const offset = (-yOffset + height / 2) / 4;

  const variants = {
    hidden: {
      scale: 1,
      opacity: above ? 0 : 0,
      y: above ? 100 : -100, // Add this line to start the element lower
      transition: { duration: 0.5 },
    },
    visible: {
      scale: 1,
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.0,
        duration: 0.5,
      },
    },
  };

  return (
    // https://v2.chakra-ui.com/docs/components/transitions
    <div
      style={{ position: "fixed", transform: "translateY(" + offset + "px)" }}
    >
      <motion.div
        initial="hidden"
        animate={controls}
        variants={variants}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "50px 0",
        }}
      >
        <Box
          gap={{ base: "20px", sm: "40px", md: "60px" }}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <motion.div
              style={{ borderRadius: "50%", overflow: "hidden" }}
              variants={variants}
            >
              <Image
                src="luke.jpg"
                alt="Lucas Jaggernauth"
                width={{ base: "100px", sm: "200px", md: "300px" }}
                height={{ base: "100px", sm: "200px", md: "300px" }}
              />
              {/* <img src="luke.jpg" alt="Founder 1" style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> */}
            </motion.div>
            <motion.div
              variants={variants}
              style={{
                marginTop: "20px",
                fontSize: "40px",
                color: "white",
                fontWeight: "bold",
                fontFamily: "Roboto",
              }}
            >
              <Text fontSize={{ base: "24px", sm: "32px", md: "40px" }}>
                Lucas
              </Text>
            </motion.div>
            <motion.div
              variants={variants}
              style={{
                marginTop: "0px",
                fontSize: "30px",
                color: "white",
                fontFamily: "Roboto",
              }}
            >
              <Text fontSize={{ base: "16px", sm: "24px", md: "32px" }}>
                Jaggernauth
              </Text>
            </motion.div>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <motion.div
              style={{ borderRadius: "50%", overflow: "hidden" }}
              variants={variants}
            >
              {/* <img src="rizz.jpg" alt="Founder 2" style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> */}
              <Image
                src="rizz.jpg"
                alt="Rees Blatt"
                width={{ base: "100px", sm: "200px", md: "300px" }}
                height={{ base: "100px", sm: "200px", md: "300px" }}
              />
            </motion.div>
            <motion.div
              variants={variants}
              style={{
                marginTop: "20px",
                fontSize: "40px",
                color: "white",
                fontWeight: "bold",
                fontFamily: "Roboto",
              }}
            >
              <Text fontSize={{ base: "24px", sm: "32px", md: "40px" }}>
                Rees
              </Text>
            </motion.div>
            <motion.div
              variants={variants}
              style={{
                marginTop: "0px",
                fontSize: "30px",
                color: "white",
                fontFamily: "Roboto",
              }}
            >
              <Text fontSize={{ base: "16px", sm: "24px", md: "32px" }}>
                Blatt
              </Text>
            </motion.div>
          </Box>
        </Box>
      </motion.div>
    </div>
  );
}

function AnimatedArrow() {
  const variants = {
    animate: {
      y: ["0%", "0%", "0%", "150%", "0%", "0%", "150%", "0%", "0%", "0%", "0%"],
      transition: {
        duration: 1.2, // Total duration for bounce
        repeat: Infinity, // Repeat bounce twice
        repeatDelay: 1.5, // Rest for 1.5 seconds after each sequence
        loop: Infinity, // Repeat indefinitely
      },
    },
  };

  return (
    <motion.div
      style={{
        position: "absolute",
        color: "white",
        fontSize: "30px",
        display: "flex",
        justifyContent: "center",
        width: "100%", // Ensure it's centered horizontally
        top: "calc(83vh)", // Adjust this value to change the distance from the bottom
      }}
      variants={variants}
      animate="animate"
    >
      <BiDownArrow />
    </motion.div>
  );
}

function App() {
  const [scrollY, setScrollY] = useState(0);
  const [overlayOpacity, setOverlayOpacity] = useState(0);
  const [isMazeGone, setIsMazeGone] = useState(false);

  const ANIMATION_TERRAIN_TRANSLATE = 0.0028;

  const meshRef = useRef();
  const [isTerrainLoading, setIsTerrainLoading] = useState(true);
  // const [isTerrainLoading2, setIsTerrainLoading2] = useState(true);
  const gltf = useLoader(GLTFLoader, "voxelsDraco.glb", (loader) => {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("https://www.gstatic.com/draco/v1/decoders/");
    loader.setDRACOLoader(dracoLoader);
  }); //, undefined, () => setIsTerrainLoading(false))

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsTerrainLoading(false);
    }, 300); // Small delay to ensure the CSS transition is rendered
    return () => clearTimeout(timeoutId);
  }, []);

  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     setIsTerrainLoading2(false);
  //   }, 10000); // Small delay to ensure the CSS transition is rendered
  //   return () => clearTimeout(timeoutId);
  // }, []);

  const terrainLoadingBg = {
    transition: "opacity 3s ease-out",
    opacity: isTerrainLoading ? 1 : 0,
    // backgroundColor: '#', // Example background color
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    backgroundColor: `#0E2A59`, // Black overlay, change color as needed
    pointerEvents: "none",
  };

  const mazeHideBackground = {
    transition: "opacity 0.4s ease-out",
    opacity: isMazeGone ? 1 : 0,
    // backgroundColor: '#', // Example background color
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    backgroundColor: `#0E2A59`, // Black overlay, change color as needed rgba(4,14,30)
    pointerEvents: "none",
  };

  useEffect(() => {
    const handleScroll = () => {
      let position = window.pageYOffset;
      // cap the scroll position at 2400
      if (position > END_OF_PAGE + 1) {
        window.scrollTo(0, END_OF_PAGE);
        position = END_OF_PAGE;
      }
      setScrollY(position);
      // Adjust these values based on when you want the animation to start and end
      if (position > 500) {
        setOverlayOpacity(Math.min(1, (position - 500) / 500));
      } else {
        setOverlayOpacity(0);
      }

      setIsMazeGone(position > MAZE_END);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      style={{
        height: "1000vh",
      }}
    >
      {/* <Canvas
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100vw',
            height: '200vh',
            backgroundColor: '#000000',
          }}
        >
          <ambientLight intensity={1.0} />
          <pointLight position={[10, 10, 10]} />
        </Canvas> */}

      <Canvas
        frameloop={scrollY < MAZE_START ? "demand" : "never"}
        gl={{ antialias: true }}
        dpr={Math.min(window.devicePixelRatio, 2) / 2}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100vh",
          backgroundColor: "#061227",
        }}
      >
        <ambientLight intensity={Math.PI / 2} />
        <spotLight
          position={[10, 10, 10]}
          angle={1}
          penumbra={1}
          decay={0.1}
          intensity={Math.PI}
        />
        <pointLight position={[-10, -10, -10]} decay={0} intensity={Math.PI} />
        {/* <Box3D position={[-1.2, 0, 1]} /> */}
        {/* <Box3D position={[1.2, 0, 0]} /> */}
        <primitive
          object={gltf.scene}
          ref={meshRef}
          position={[4, -2 + scrollY * ANIMATION_TERRAIN_TRANSLATE, 0]}
          rotation={[0, (Math.PI / 180) * 135, 0]}
          scale={1} // You can adjust the scale based on the actual size of your model
        />
      </Canvas>

      <AnimatedArrow />

      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100vh",
          backgroundColor: `rgba(4,14,30,${overlayOpacity})`, // Black overlay, change color as needed
          pointerEvents: "none", // Allows interaction with the canvas below
        }}
      />

      <Maze
        yOffset={scrollY - MAZE_START}
        height={MAZE_RENDER_FINISH - MAZE_START}
      />

      <div style={mazeHideBackground} />

      <div style={terrainLoadingBg} />

      <Box
        width="100%"
        height="100vh"
        // backgroundColor={"#000000"}
        padding={{ base: 4, sm: 4, md: 16 }}
        // make it square
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        position="relative"
      >
        <Box
          backgroundColor="#1A212B"
          width="100%"
          height="100vh"
          maxW={{ base: "sm", sm: "2xl", md: "2xl" }}
          maxH={{ base: "sm", sm: "md", md: "lg" }}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          borderColor={"ffffff"}
          borderWidth={2}
        >
          <Box width="4xl" height="auto">
            <Text
              as="h1"
              fontWeight={300}
              fontFamily="Roboto"
              fontSize={{ base: "7xl", sm: "7xl", md: "8xl" }}
              bgClip="text"
              textAlign="center"
              pb="0.1em"
              // Change spacing between lines
              lineHeight="105%"
              maxWidth="4xl"
              mb={8}
            >
              <Text fontWeight={700} as="span" color="#5B97EB">
                relu
              </Text>
              <Text fontWeight={400} as="span" color="#175DBE">
                .gg
              </Text>
            </Text>

            <Text
              paddingTop="20px"
              as="h1"
              fontWeight={600}
              fontSize={{ base: "2xl", sm: "2xl", md: "2xl" }}
              bgGradient="linear(to-b, rgba(255, 255, 255, 1), rgba(230, 230, 230, 0.8))"
              bgClip="text"
              textAlign="center"
              pb="0.1em"
              mt={-4}
              mb="16px"
              // Change spacing between lines
              lineHeight="105%"
              maxWidth="8xl"
            >
              the game studio of the future
            </Text>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            gap={4}
          >
            <Youtube />
            <Discord />
          </Box>
        </Box>

        <ProjectSection
          inView={scrollY >= PROJECT_START && scrollY < PROJECT_END}
          yOffset={scrollY - PROJECT_START}
          height={PROJECT_END - PROJECT_START}
        />
        <FoundersSection
          inView={scrollY >= FOUNDER_START && scrollY < FOUNDER_END}
          yOffset={scrollY - FOUNDER_START}
          scrollY={scrollY}
          picSize={{ base: 100, sm: 200, md: 300 }}
          height={FOUNDER_END - FOUNDER_START}
        />
        <ProceduralGenSection
          inView={scrollY >= MAZE_SECTION_APPEAR && scrollY < MAZE_END}
          yOffset={scrollY - MAZE_SECTION_APPEAR}
          height={MAZE_END - MAZE_SECTION_APPEAR}
        />

        {scrollY >= END_OF_PAGE && (
          <Box
            style={{
              position: "fixed",
              top: "45%",
              left: "50%",
              transform: "translateX(-50%)",
            }}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={8}
          >
            <Text
              textColor={"#ffffff"}
              fontSize="lg"
              // fontSize={{ base: "md", sm: "lg", md: "xl" }}
              textAlign="center"
              w="lg"
            >
              Congrats! You've reached the end of the page.
            </Text>
            <Discord />
          </Box>
        )}

        <Box
          position="fixed"
          top="50%"
          right="00px"
          transform="translateY(-50%)"
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          gap={0}
        >
          <Box
            width={{ base: "16px", md: "50px" }}
            height="14px"
            borderRadius="50%"
            onClick={movePosition(0)}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            cursor={"pointer"}
          >
            <Box
              width="10px"
              height="10px"
              borderRadius="50%"
              backgroundColor={
                scrollY < 1000 && scrollY < MAZE_SECTION_APPEAR
                  ? "white"
                  : "gray"
              }
              onClick={movePosition(0)}
              cursor={"pointer"}
            />
          </Box>

          <Box
            width={{ base: "16px", md: "50px" }}
            height="14px"
            borderRadius="50%"
            onClick={movePosition(MAZE_RENDER_FINISH + 1)}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            cursor={"pointer"}
          >
            <Box
              width="10px"
              height="10px"
              borderRadius="50%"
              backgroundColor={
                scrollY >= MAZE_START && scrollY < MAZE_END ? "white" : "gray"
              }
              onClick={movePosition(MAZE_RENDER_FINISH + 1)}
              cursor={"pointer"}
            />
          </Box>

          <Box
            width={{ base: "16px", md: "50px" }}
            height="14px"
            borderRadius="50%"
            onClick={movePosition((PROJECT_START + PROJECT_END) / 2)}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            cursor={"pointer"}
          >
            <Box
              width="10px"
              height="10px"
              borderRadius="50%"
              backgroundColor={
                scrollY >= PROJECT_START && scrollY < PROJECT_END
                  ? "white"
                  : "gray"
              }
              onClick={movePosition((PROJECT_START + PROJECT_END) / 2)}
              cursor={"pointer"}
            />
          </Box>

          <Box
            width={{ base: "16px", md: "50px" }}
            height="14px"
            borderRadius="50%"
            onClick={movePosition((FOUNDER_START + FOUNDER_END) / 2)}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            cursor={"pointer"}
          >
            <Box
              width="10px"
              height="10px"
              borderRadius="50%"
              backgroundColor={
                scrollY >= FOUNDER_START && scrollY < FOUNDER_END
                  ? "white"
                  : "gray"
              }
              onClick={movePosition((FOUNDER_START + FOUNDER_END) / 2)}
              cursor={"pointer"}
            />
          </Box>

          {/*<Box
            width={{ base: "16px", md: "50px" }}
            height="14px"
            borderRadius="50%"
            onClick={movePosition(END_OF_PAGE + 1)}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              width="10px"
              height="10px"
              borderRadius="50%"
              backgroundColor={scrollY >= END_OF_PAGE ? "white" : "gray"}
              onClick={movePosition(END_OF_PAGE + 1)}
            />
            </Box>*/}
        </Box>
      </Box>
    </Box>
  );
}

export default App;
