import "./App.css";
import { Link, Button } from "@chakra-ui/react";
import "./App.css";
import { FaDiscord, FaYoutube } from "react-icons/fa";

function Discord() {
  return (
    <Link
      href="https://discord.gg/QzdvzBfrZK"
      style={{ textDecoration: "none" }}
      target="_blank"
    >
      <Button
        variant="outline"
        bg={"white"} // Change the background color to white
        rounded={"full"}
        px={6}
        size="lg"
        _hover={{
          bg: "rgba(255,255,255,0.2)",
        }}
      >
        <FaDiscord
          style={{
            marginRight: "-16px",
            marginLeft: "-16px",
            fontSize: "32px",
          }}
        />{" "}
        {/* Set the font size to 24px */}
      </Button>
    </Link>
  );
}

function Youtube() {
  return (
    <Link
      href="https://www.youtube.com/@REESandLUKE"
      style={{ textDecoration: "none" }}
      target="_blank"
    >
      <Button
        variant="outline"
        bg={"white"} // Change the background color to white
        rounded={"full"}
        px={6}
        size="lg"
        _hover={{
          bg: "rgba(255,255,255,0.2)",
        }}
      >
        <FaYoutube
          style={{
            marginRight: "-16px",
            marginLeft: "-16px",
            fontSize: "32px",
          }}
        />{" "}
        {/* Set the font size to 24px */}
      </Button>
    </Link>
  );
}

export { Discord, Youtube };
